import React, { useMemo, useCallback, useState, useEffect, useContext, createContext } from "react";

import { debounce } from "lodash";

import { client } from "../../services/api";
import { client_dexscreener } from "../../services/api_dexscreener";
import { client_healthcheck } from "../../services/api_healthcheck";
import { client_etherscan } from "../../services/api_etherscan";
import { client_cg } from "../../services/api_coingecko";

import { useWallet } from "../useWallet";
import { useCore } from "../useCore";
import { useNavigator } from "../useNavigator";

import useSocketContext from '../useSocketContext';

import toast from 'react-simple-toasts';


import { buildToken } from "../../utils/tools";
import { eTrendingData, supportedChains, chainMeta, swaps } from "../../utils/data";

var parse = require('html-react-parser');

const dexContext = createContext();

function useProvideDex() {

    const navigator = useNavigator();

    const { walletAddress, user, handleUser, inApp} = useWallet();
    const {
        sidebarRight,
        handleSidebarRight,
        handleModalVisible,
        handleTokenListModal,
        dexSearchModal,
        handleDexSearchModal,
        updateTokenData,
        handleUpdateTokenData,
        newNotification,
        handlePlaySound,
        basePage,
        listPage,
        ethTrending,
        mobileView,
        handleMobileView
    } = useCore();


    


    const { emit } = useSocketContext();


    const [ dexSearch, setDexSearch ] = useState(null);
    const [ dexSearchPairs, setDexSearchPairs ] = useState([]);

    const [swapExchange, setSwapExchange] = useState("uniswap");
    const [ swapUrl, setSwapUrl ] = useState(null);

    const [dexResults, setDexResults] = useState([]);
    const [token, setToken] = useState(null);
    const [pair, setPair] = useState(null);

    const [tokenHistory, setTokenHistory] = useState([]);

    const [tokenPair, setTokenPair] = useState(null);

    const [ethTrendingChain, setEthTrendingChain] = useState("ethereum");
    const [ethTrendingKey, setEthTrendingKey ] = useState('bt');  // bt, sg, tg, dt
    const [ trendingChain, setTrendingChain ] = useState("ethereum");

    
    const [ethTrendingTokens, setEthTrendingTokens] = useState({});
    const [trendingTokens, setTrendingTokens] = useState([]);
    const [trendingTokensChannels, setTrendingTokensChannels] = useState({});
    const [trendIntervalStarted, setTrendIntervalStarted] = useState(null);

    const [ethFactor, setEthFactor] = useState(.992);

    const [tokenTwitterUsers, setTokenTwitterUsers] = useState([]);

    const [pairs, setPairs] = useState({});
    const [tokenPairs, setTokenPairs] = useState({});
    const [caToken, setCaToken] = useState(null);

    const [portfolioTokens, setPortfolioTokens] = useState(null);

    const [newPairs, setNewPairs] = useState({});
    const [trendingPairs, setTrendingPairs] = useState({});
    const [tickerPairs, setTickerPairs] = useState({});

    const [ tokenDataTime, setTokenDataTime ] = useState('m5');

    const [tokenOrder, setTokenOrder] = useState(true);
    const [tokenSort, setTokenSort] = useState("liquidityAt");
    const [tokenFilters, setTokenFilters] = useState(null);

    const [showTicker, setShowTicker ] = useState(false);
    const [dexView, setDexView] = useState("list");
    const [listView, setListView] = useState("home");
    const [trendingView, setTrendingView] = useState("list");
    const [dexViewChartInit, setDexViewChartInit] = useState(false);
    const [dexViewSwapInit, setDexViewSwapInit] = useState(false);

    const [editFilters, setEditFilters] = useState(false);

    const [tokenLists, setTokenLists ] = useState([]);
    const [adminTokenLists, setAdminTokenLists ] = useState([]);
    const [tokenListId, setTokenListId ] = useState(null);
    const [ tokenList, setTokenList ] = useState(null);

    const [exploreLists, setExploreLists] = useState([]);
    
    
    const [listTokens, setListTokens] = useState([]);
    const [ homePartnerList, setHomePartnerList] = useState([]);
    const [ homeMeta, setHomeMeta ] = useState([]);
    const [welcomeMessage, setWelcomeMessage] = useState("");

    const [dexTimer, setDexTimer] = useState(0);
    const [dexUpdateTime, setDexUpdateTime] = useState(null);

    const [tokenHealth, setTokenHealth] = useState(null);

    const [blankOwnerAddress, setBlankOwnerAddress] = useState("0x0000000000000000000000000000000000000000");
    const [deadWalletAddress, setDeadWalletAddress] = useState("0x000000000000000000000000000000000000dEaD");

    const [loading, setLoading] = useState({
        dex: false,
        trending: false,
        dexsearch: false,
        health: false,
        scan: false,
        list: false
    });

    const [dexLoadDelay, setDexLoadDelay] = useState(true);

    const [apeSettings, setApeSettings] = useState({
        amount: .05,
        slippage: 11,
    })


    const [tokenToolbarVisible, setTokenToolbarVisible] = useState(false);


    const [ tbx, setTbx ] = useState(0);
    const [ tby, setTby ] = useState(0);

    const [ dexChartVisible, setDexChartVisible ] = useState(false);

    const [ swapVisible, setSwapVisible ] = useState(false);



    useEffect(() => {
        if(listPage){
            // console.log(listPage)
        }
    }, [ listPage ]);


    // update on the minute. need to call to db instead of dex
    useEffect(() => {
        if(token && walletAddress){
            const interval = setInterval(getCurrentDex, 30000);
            return () => clearInterval(interval);
        }else if(token && !walletAddress){
            const interval = setInterval(getCurrentDex, 60000);
            return () => clearInterval(interval);
        }

        if(token && !chainMeta[token.chainId].swaps.includes(token.chainId)){
            setSwapExchange(chainMeta[token.chainId].swaps[0]);
        }

        if(!walletAddress && tokenLists.length){
            setTokenLists([]);
        }

        // if(!walletAddress && token){
        //     setToken(null);
        //     setCaToken(null);
        // }

    }, [ token, walletAddress ]);

    useEffect(() => {

        if(inApp && user){
            // fetchNewPairs();
            // fetchTrendingPairs();

        }

    }, [ inApp, user ]);

   


    useEffect(() => {
        if(user && !tokenLists.length){
            fetchTokenLists(walletAddress);
            // console.log(user);
        }
        if(user){
            fetchPortfolioTokens(walletAddress);
        }
    }, [ user ]);



    // useEffect(() => {
    //     if(inApp && walletAddress && !trendIntervalStarted){
    //         const trendinterval = setInterval(fetchTickerPairs, 30000);
    //         return () => clearInterval(trendinterval);
    //         setTrendIntervalStarted(true);
    //     }

    // }, [ inApp, walletAddress ]);

    useEffect(() => {
        // console.log(tokenFilters);
    }, [ tokenFilters ]);

    useEffect(() => {
        if(updateTokenData){

            let np = {...newPairs};
            let tp = {...trendingPairs};

            if(updateTokenData.delete !== undefined && updateTokenData._id !== undefined){
                let dt;
                let nptemp = {};
                for(var key in np){
                    if(np[key]._id !== updateTokenData._id){

                        nptemp[key] = {...np[key]};
                    }else{
                        dt = {...np[key]};
                    }
                }
                setNewPairs(nptemp);

                newNotification({
                    type: 'failure',
                    msg: `${dt !== undefined && dt.data !== undefined ? dt.data.baseToken.symbol : dt !== undefined && dt.symbol ? dt.symbol : 'unknown token'} removed liquidity.`
                })

            }else{

                for(var key in updateTokenData){
                    if(np[key] !== undefined){
                        let coremeta = {...updateTokenData[key]};
                        delete coremeta.data;
                        // console.log(coremeta)
                        // console.log(updateTokenData[key].data)
                        // console.log(np[key]);
                        np[key] = { ...np[key], ...coremeta, data: { ...np[key].data, ...updateTokenData[key].data }}
                        // console.log(np[key]);
                    }

                }
                setNewPairs(np);
                setTrendingPairs(tp);

            }


        }
    }, [ updateTokenData ]);

    const getCurrentDex = useCallback( async () => {

        // console.log('get current dex what!!!?', token);
        // need to get this from api and update price if needed.
        if(token.pair == undefined){
            return false;
        }
        let d = {
            chain: token.pair.chainId,
            pa: token.pair.address
        }
       
        let tp = await client.getTokenByPair(d);
      
        if(tp.ok && tp.data !== undefined && tp.data){
            let t = tp.data;

            if(walletAddress){
                if(t.pair.data.priceUsd > token.pair.data.priceUsd){
                    if(user.sfx.priceup){
                       handlePlaySound(user.sfx.priceup);
                    }
    
                }else if(t.pair.data.priceUsd < token.pair.data.priceUsd){
                   if(user.sfx.pricedown){
                       handlePlaySound(user.sfx.pricedown);
                   }
                }
            }
            

            handleTokenPair(t);

        }


        handleTokenToolbarVisible(true);
   

    }, [token, setTokenPair, user]);



    const debouncedDexSearch = useMemo(
        () => debounce(handleDexSearchModal, 1500)
    , []);
    // Stop the invocation of the debounced function
    // after unmounting
    useEffect(() => {
    return () => {
        debouncedDexSearch.cancel();
    }
    }, []);

    const handleDexSearchPairs = (pairs) => {
        setDexSearchPairs(pairs);
    }

    const handleTokenDataTime = (v) => {
        setTokenDataTime(v);
    }

    const handleDexSearch = (ds) => {
        setDexSearch(ds);
        // debounce(test, 1500);

    }

    const handleTokenList = (l) => {
        setTokenList(l);
    }

    const handleSwapExchange = (v) => {
        setSwapExchange(v);
    }

    const handleSwapUrl = (v) => {
        setSwapUrl(v);
    }

    const handleTokenFilters = (v) => {

        setTokenFilters(v);

        fetchPairsQuery(v);
        // debounce(test, 1500);

    }

    const handleTokenOrder = (v) => {
        setTokenOrder(v);
        // debounce(test, 1500);

    }

    const handleTokenSort = (v) => {
        setTokenSort(v);
        // let q = v.split(".").join("|");
        // fetchPairsQuery();
        // debounce(test, 1500);

    }

    const handleDexChartVisible = (v) => {
        setDexChartVisible(v);
    }

    const handleSwapVisible = (v) => {
        setSwapVisible(v);
    }

    const handleToken = (v) => {
        setToken(v);
        
        
    }
    const handleTokenToolbarVisible = (v, x=0, y=0) => {
        // console.log(v,x,y)
        setTokenToolbarVisible(v);
        if(x > 0 || y > 0){
            setTbx(x);
            setTby(y);
        }

    }

    const handleScreenClickDims = (x=0,y=0) => {
        setTbx(x);
        setTby(y);
    }
    
    const closeTokenPair = async() => {
        if(token){
            // if((!tp || tp.address !== token.address) && token.chainId !== undefined)
            setTokenHistory(prevHistory => {
                // Remove any existing entry with the same address
                const filteredHistory = prevHistory.filter(item => item.address !== token.address);
                
                // Add the new token at the start of the array
                const newHistory = [token, ...filteredHistory];
                
                // Limit the array to the first 5 entries
                return newHistory.slice(0, 7);
            });
        }
        setCaToken(null);
        setToken(null);

    }

    const handleTokenPair = async(t=null, p=null) => {

        setTokenHealth(null);

        let tp = await buildToken(t,p);

        if(!sidebarRight && tp && tp.pair?.data && tp.address !== caToken){
            handleSidebarRight(true);
        }
        if(token){
            if((!tp || tp.address !== token.address) && token.chainId !== undefined)
            setTokenHistory(prevHistory => {
                // Remove any existing entry with the same address
                const filteredHistory = prevHistory.filter(item => item.address !== token.address);
                
                // Add the new token at the start of the array
                const newHistory = [token, ...filteredHistory];
                
                // Limit the array to the first 5 entries
                return newHistory.slice(0, 7);
            });
            
            
        }
        
        console.log(tp)

        setToken(tp);
       
        

        if(tp){
            setCaToken(tp.address);
            // let url = `/${tp.pair.data.chainId}/${tp.pair.data.pairAddress}`;
            // if(view){
            //    url += `?view=${view}`;
            // }
            // navigator.push(url)
        }else{
            setCaToken(null);
        }

    }

    const handleLoading = (d) => {
        let l = {...loading, ...d };
        setLoading(l);
    }


    async function searchDex(q){

        handleLoading({dexsearch: true});

        let qlength = q.length;
       
        // let query = q.replace('$','').replace('@','').replace('#','');
        let query = q.replace('@','').replace('#','');
        setDexSearch(query);

        let dexlocalsearch = await client.searchPairs(query);
        // console.log(dexlocalsearch)

        let dexlookup = await client_dexscreener.searchPairs(query);

        // console.log(dexlookup)
        let pairs = [];
        let dp = dexlookup.data.pairs;

        for(var i=0;i<dp.length;i++){
            if(dp[i].liquidity !== undefined
                && dp[i].liquidity.usd > 500
                && dp[i].fdv > 1000
                // && ( dp[i].chainId == "ethereum" || dp[i].chainId == "pulsechain" ) // multichain coming, dont get com
                && supportedChains.includes(dp[i].chainId)
                // && ( dp[i].dexId == "uniswap" )
                // && (( dp[i].liquidity.usd/dp[i].fdv > .01 && dp[i].fdv < 1000000000 )
                //      || ( dp[i].fdv > 1000000000 && dp[i].liquidity.usd > 20000 ))
                // && dp[i].baseToken.name.length < 100
            ){
                pairs.push(dp[i]);
            }
        }

      
        let t = Date.now();
        setDexResults({...dexlookup.data, pairs: pairs, updated: t });
        if(pairs.length){

            let q = query.toLowerCase();
            const dPairs = pairs.filter(t => t.baseToken.symbol.toLowerCase() == q || t.baseToken.symbol.toLowerCase() == '$' + q );
            // console.log(dPairs,'filtered')

            let loadFirst = null;
            let pair = null;
            // console.log('search input',pairs[0].baseToken.address.toLowerCase() == q.toLowerCase())
            if( pairs[0].baseToken.symbol.toLowerCase() == q.toLowerCase()
                || pairs[0].baseToken.symbol.toLowerCase() == '$' + q.toLowerCase()
                || pairs[0].pairAddress.toLowerCase() == q.toLowerCase()
                || pairs[0].baseToken.address.toLowerCase() == q.toLowerCase() ){
                pair = pairs[0];
                loadFirst = true;
            }else{
                let largestCap = 0;
                var lci = null;
                let nextCap = 0;
                for(var i=0;i<pairs.length;i++){
                    if(pairs[i].fdv > largestCap){
                        largestCap = pairs[i].fdv;
                        lci = i;
                    }else if(pairs[i].fdv > nextCap){
                        nextCap = pairs[i].fdv;
                    }
                }

                loadFirst = (largestCap > 500000 && nextCap < 15000) || (largestCap > 5000000 && nextCap < 100000);
                pair = pairs[lci];
            }

            let ca = pair.baseToken.address;


            if(loadFirst && pairs.length < 2){
            
                let p = loadFirst ? pair : pairs[0];
            
                setTokenHealth(null);
                // setTokenPair({data: { ...p }, updated: t, image: null });
                navigator.push(`/${p.chainId}/${p.pairAddress}`);
                // fetchTokenPair(pair.pairAddress);
            
                setDexUpdateTime(t);
                handleModalVisible(false);
                handleDexSearchModal(false);
                handleDexSearch('');
                handleDexSearchPairs(pairs);
            
            
                // handleTokenToolbarVisible(true);
                // searchCoinGecko(pair, ca);
            
                // if(!sidebarRight){
                //     handleSidebarRight(true);
                // }
            
            }else{
                // handleLoading({dex:false});
                handleModalVisible(true);
                handleDexSearchModal(true);
                // console.log(pairs);
                handleDexSearchPairs(pairs);
            }

            // searchCoinGecko(pair, ca);
            handleLoading({dexsearch:false});

        }else{
            toast('No token found');
            handleModalVisible(true);
            handleDexSearchModal(true);
            handleLoading({dexsearch:false});

        }


    }

    async function fetchTokenPair(chain="ethereum", pa){

        if(!dexLoadDelay){
            handleLoading({ ...loading, dex: true });
        }
        
        
        let data = {
            chain,
            pa
        }
        
        let tp = await client.getTokenByPair(data);

        // let tkn = {...tp.data};
        // let p = tkn.pair;
        // delete tkn.pair;
   
        if(tp.data){

            
            
            if( !token || tp.data.address !== token.address){
                handleMobileView("sidebar");
            }

            setToken(tp.data);
            
            
            if(!sidebarRight){
                handleSidebarRight(true);
            }
            setCaToken(tp.data.address);
            // fetchTokenTags(tp.data.symbol, "cash");
        }
        setDexLoadDelay(true);
        // setTimeout(() => {
            handleLoading({ ...loading, dex: false });
        // }, 300);

        setTimeout(() => {
            setDexLoadDelay(false);
        }, 500);
        
    }

    async function fetchTokenTags(symbol, type="cash"){

        let tt = await client.getTwitterPostsByTag(symbol, type);
        if(tt.data){
            setTokenTwitterUsers(tt.data);
        }

    }

    async function fetchFromDex(q){
        let dexdata = await client_dexscreener.getPairs(q);
        // let pairs = [];
        // let dp = dexlookup.data.pairs;
    }



    async function handleTokenPairUpdate(u){

        if(tokenPair._id == u._id){
            let tu = {
                ...tokenPair,
                ...u
            }
            setTokenPair(tu);
        }
    }

    async function handleHealthCheck(ca){
        setTokenHealth(null);
        handleLoading({health: true});
        let hcheck = await client_healthcheck.checkHoneypot(ca);
        let t = Date.now();
        let h = {...hcheck.data, ca: ca, updated: t, healthUpdatedAt: t };
        console.log(h)
        setTokenHealth(h);
        setTokenPair({...tokenPair, health: {...h}});

        handleLoading({health: false});
    }

    async function fetchNewPairs(){
        setNewPairs({});
        let nps = await client.getNewPairs();

        let tempnp = {};
        // console.log(nps)

        if(nps.data){
            let pa = [...nps.data];
            for(var i=0;i<pa.length;i++){
                // console.log(nps.data[i].data.baseToken)
                if(pa[i].data !== undefined){
                    tempnp[pa[i].data.baseToken.address] = { ...pa[i] };
                    tempnp[pa[i].data.baseToken.address].new = true;
                }
                //
            }
            // console.log(tempnp, 'new pairs')
            setPairs({...pairs, ...tempnp });
          
        }



        // console.log('new pairs >>>>>>>>>>>>>>>>>>>>',tempnp);
    }

    async function fetchExploreLists(){

        let el = await client.getExploreLists(walletAddress);

        if(el.data && el.ok){
            setExploreLists(el.data);
        }
        

    }

    




    async function fetchTickerPairs(){

        let nps1 = await client.getEthData();
       
        setTickerPairs({});
        let nps = await client.getTickerPairs();
        let tempnp = {};

        if(nps.data){
            for(var i=0;i<nps.data.length;i++){
                // console.log(nps.data[i].data.baseToken)
                if(nps.data[i].data !== undefined){
                    tempnp[nps.data[i].data?.baseToken.address] = { ...nps.data[i] };
                }
            }
            setTickerPairs(tempnp);
        }

    }

    async function fetchPairsQuery(passq=null){
        // setTrendingPairs({});
        let so = tokenOrder ? 'desc' : 'asc';

        let f = passq ? { ...passq } : {};

        f={
            ...f,
            sort:tokenSort,
            order:so
        }
        let q="";
        let c=0;
        for(var key in f){
            if(c>0){
                q += "&";
            }
            q += `${key}=${f[key]}`;
            c++;
        }

        let eq = encodeURI(q);

        let pq = await client.getPairsQuery(`${eq}`);

        let temppq = {};
        if(pq.data){

            for(var i=0;i<pq.data.length;i++){
                // console.log(nps.data[i].data.baseToken)
                if(pq.data[i].data !== undefined){
                    temppq[pq.data[i].data.baseToken.address] = { ...pq.data[i] };
                }
                //
            }

            // setTokenPairs({...tokenPairs, ...temppq})
            setTokenPairs({ ...temppq});


        }


        // console.log('query  pairs >>>>>>>>>>>>>>>>>>>>',temppq);
    }

    async function fetchHomeMeta(wa){
     
        let et = await client.getCoreHomeMeta(walletAddress);
      
        if(et.data){
            let hm = {...et.data};
            setHomeMeta(hm);
            setWelcomeMessage(hm.welcomeMessage)
        }

    }

    const removeSiteFeaturedToken = async () => {
        let uAdmin = await client.updateAdmin({
            data: {
                featuredMetaToken: null
            }
        });

        let hm = {...homeMeta};
        hm.fmt = null;
        setHomeMeta(hm);
    }

    const addSiteFeaturedToken = async (t) => {
        // let uAdmin = await client.updateAdmin({
        //     data: {
        //         featuredMetaToken: null
        //     }
        // });

        let hm = {...homeMeta};
        hm.fmt = {...t};
        setHomeMeta(hm);
    }
    

    async function fetchEthTrendingTokens(){
        handleLoading({ ...loading, trending: true });

        let pa = "";
        let ta = "";
        let pairSearch=true;

        let chain = ethTrendingChain;
        let tacount = 0;
        let pacount = 0;
        for(var key in eTrendingData[chain]){
            for(var i=0;i<ethTrending[key].length;i++){
                
                if(ethTrending[key][i].pair){
                    if(pacount>0){
                        pa+=",";
                    }
                    pa += ethTrending[key][i].pair;
                    pacount+=1;
                }else{
                    if(tacount>0){
                        ta+=",";
                    }
                    ta += ethTrending[key][i].token;
                    tacount +=1;
                   
                }
            }
        }
        
        
        let et = null;
        let ep = null;
        if(pacount > 0){
            ep = await client.getPairsByPairAddress(pa);
        }
        if(tacount > 0){
            et = await client.getPairsByTokenAddress(ta);
        }

        let fullt = [];
        console.log(ep,et)
        if(ep && ep.data !== undefined && ep.data){
            fullt = [...ep.data];
        }
        if(et && et.data !== undefined && et.data){
            fullt = [...fullt, ...et.data];
        }
        // let fullt = [...ep,...et];
        console.log(fullt);

        let ft = fullt
        .filter(p => p._id !== undefined)
        .filter(
            (item, index, self) =>
              index === self.findIndex((obj) => obj._id === item._id)
        );

        console.log(ft);

        let tChannels = {};

        for(var i=0;i<ft.length;i++){
            let ta = String(ft[i].ta).toLowerCase();
            let pa = String(ft[i].address).toLowerCase();
            // let pa = ett[etk][i].pair?.address;
            // console.log(ta,pa);
            let trending = {};
            for(var key in eTrendingData[ethTrendingChain]){
                if(tChannels[key] == undefined){
                    tChannels[key] = {
                        count: 0,
                        name: eTrendingData[ethTrendingChain][key].name
                    }
                }
                var found = false;
               
                for(var j=0;j<ethTrending[key].length;j++){
                    // console.log('t',ethTrending[key][j].token,'p',ethTrending[key][j].pair)
                    let etok = String(ethTrending[key][j].token);
                    let epair = String(ethTrending[key][j].pair);
                    if( (etok !== undefined && etok && ta == etok.toLowerCase()) ||
                        (epair !== undefined && epair && pa == epair.toLowerCase())){
                        
                        trending[key] = {
                            rank: j,
                            startedAt: ethTrending[key][j].startedAt
                        };
                        found = true;
                        // console.log('found',i,key,j)

                        tChannels[key].count = tChannels[key].count + 1;
                    }
                    
                    
                }
                
                if(!found){
                    // trending[key] = -1;
                }
            }

            // console.log(i, trending)

            ft[i].trending = {...trending};

            // console.log(ett[etk],trending)
            // console.log(trending)
        
        }




        let ett = {...ethTrendingTokens};
        // ett[ethTrendingKey] = et && et.data !== undefined && et.data && et.data.length ? [...et.data] : [];
        

        // iterate through trending keys for chain
        
        
            // iterate through trending channels;
        // let etk = ethTrendingKey;
        // for(var i=0;i<ett[etk].length;i++){
        //     let ta = String(ett[etk][i].ta).toLowerCase();
        //     let pa = String(ett[etk][i].address).toLowerCase();
        //     // let pa = ett[etk][i].pair?.address;
        //     // console.log(ta,pa);
        //     let trending = {};
        //     for(var key in eTrendingData[ethTrendingChain]){
               
        //         var found = false;
        //         for(var j=0;j<ethTrending[key].length;j++){
        //             // console.log('t',ethTrending[key][j].token,'p',ethTrending[key][j].pair)
        //             let etok = String(ethTrending[key][j].token);
        //             let epair = String(ethTrending[key][j].pair);
        //             if( (etok !== undefined && etok && ta == etok.toLowerCase()) ||
        //                 (epair !== undefined && epair && pa == epair.toLowerCase())){
                        
        //                 trending[key] = {
        //                     rank: j,
        //                     startedAt: ethTrending[key][j].startedAt
        //                 };
        //                 found = true;
        //                 // console.log('found',i,key,j)
        //             }
                    
        //         }
        //         if(!found){
        //             // trending[key] = -1;
        //         }
        //     }

        //     // console.log(i, trending)

        //     ett[etk][i].trending = {...trending};

        //     // console.log(ett[etk],trending)
        //     // console.log(trending)
        
        // }

        let ettr = ft.filter((item) => ethTrendingKey in item.trending);
        ett[ethTrendingKey] = [...ettr];
        
        // setEthTrendingTokens(ett);

        setTrendingTokens(ft);
        setTrendingTokensChannels(tChannels);
        
        
        
        setTimeout(() => {
            handleLoading({ ...loading, trending: false });
        }, 50);
    }

    async function fetchPortfolioTokens(wa){
        let pt = await client.getPortfolioTokens(wa);
      
        if(pt.data && pt.data.length){
            let pttemp = [...pt.data];
            setPortfolioTokens(pttemp);
        }else if(pt.data && !pt.data.length){
            setPortfolioTokens([]);
        }
    }

    async function fetchTokenList(slug){

        let d = {
            slug: slug,
            wa: walletAddress
        }
        let lt = await client.getTokenList(d);
       
        if(lt.data){
            let l = {...lt.data}
            
            setTokenList(lt.data);
        }
        
        // if(lt && lt.data?.length){
        //     setListTokens(lt.data);
        // }else{
        //     setListTokens([]);
        // }
    

    }

    async function duplicateTokenList(d){
        let dup = await client.duplicateTokenList(d);

        if(dup.data){
            let l = {...dup.data}

            let tls = [...tokenLists, l];
            
            setTokenLists(tls);
        }
        // fetchTokenLists();
    }

    async function handleListTokens(lt){
        setListTokens(lt);
    }

    async function fetchTokenLists(wa){

        let tls = await client.getTokenLists(wa);
        let tlists = tls.data;
        if(tlists){
            for(var i=0;i<tlists.length;i++){
                if(tlists[i].slug == listPage){
                    let tl = {
                        ...tokenList,
                        ...tlists[i],
                        featuredToken: {...tokenList.featuredToken},
                        tokens: [...tokenList.tokens],
                        owner: {...tokenList.owner}
                    }

                    if(tlists[i].tokens.length < tokenList.tokens.length){
                        tl.tokens = [...tlists[i].tokens];
                    }
                    setTokenList(tl);
                }
            }
        }
        
        setTokenLists(tls.data);

    }

    async function fetchAdminTokenLists(wa){

        let tls = await client.getAdminTokenLists(wa);
        let alists = tls.data;
        if(alists){
            setAdminTokenLists(alists);
        }
        

    }

    function handleNewPair(p){
        fetchNewPairs();

    }

    function handleDexView(v){
        setDexView(v);
        if(v=="chart"){
            setDexViewChartInit(true);
        }
        if(v=="swap"){
            setDexViewSwapInit(true);
        }
    }

    function handleTrendingView(v){
        if(v == trendingView){
            setTrendingView("list");
        }else{
            setTrendingView(v);
        }
        
    }

    function handleListView(v){
        setListView(v);
        // if(v=="chart"){
        //     setDexViewChartInit(true);
        // }
        // if(v=="swap"){
        //     setDexViewSwapInit(true);
        // }
    }

    

    function handleEditFilters(v){
        setEditFilters(v);
    }

    function handleDexViewChartInit(v){
        setDexViewChartInit(v);
    }

    function handleDexViewSwapInit(v){
        setDexViewSwapInit(v);
    }

    function handleEthTrendingKey(v){
        setEthTrendingKey(v);
        for(var key in eTrendingData){
            for(var subkey in eTrendingData[key]){
                if(v == subkey){
                    setEthTrendingChain(key);
                }
            }
        }
    }

    
    function handleShowTicker(v){
        setShowTicker(v);
    }

    function handleTrendingChain(v){
        setTrendingChain(v);
    }

    

    async function handleAddToTokenList(l, t){
        let d = {
            wa: walletAddress,
            lid: l._id,
            tid: t._id,
        }
        let listupdate = await client.addTokenToList(d);

        toast(parse(`<span className="mute">${t.symbol}</span> added to <span className="mute">${listupdate.data.name}</span>`));
        fetchTokenLists(walletAddress);

        if(listPage == l.slug){
            let lt = [...listTokens];
            lt.push(t);
            
            setListTokens(lt);
        }

    }

    async function saveListOrder(){
        console.log('saving list order')
        let lt = [];
        let listTokens = [...tokenList.tokens];
        for(var i=0;i<listTokens.length;i++){
            lt.push(listTokens[i]._id);
        }
        let d = {
            _id: tokenList._id,
            tokens: [...lt]
        }
        let lu = await client.editTokenList(d);
        
    }

    async function removeTokenFromList(l, p){
        let d = {
            wa: walletAddress,
            lid: listPage,
            tid: p._id,
        }
        let listupdate = await client.removeTokenFromList(d);

        toast(parse(`<span className="mute">${p.symbol}</span> removed from <span className="mute">${listupdate.data.name}</span>`));
        fetchTokenLists(walletAddress);

        let lt = [...tokenList.tokens];
        console.log(lt)
        let ltu = [];
        for(var i=0;i<lt.length;i++){
            let itemid = lt[i]._id;
            if(itemid !== d.tid){
                ltu.push(lt[i]);
            }
            
        }
        setTokenList({
            ...tokenList,
            tokens: [...ltu]
        });

        console.log(tokenList)


    }

    async function createTokenList(data) {

        const c = { ...data };

        const response = await client.createTokenList(c);
        fetchTokenLists(walletAddress);

        // setCollections({ ...collections, [response.data._id]: response.data });
        // setThemesRevert({ ...themesRevert, [response.data._id]: response.data })
    }

    async function deleteTokenList(data) {

        const c = { ...data };
        let lid = c.lid;
        let tls = [];
        console.log(tokenLists.length)
        for(var i=0;i<tokenLists.length;i++){
            console.log(tokenLists[i]._id,lid);
            if(tokenLists[i]._id !== lid){
                tls.push(tokenLists[i]);
            }
        }

        setTokenLists(tls);

        toast(parse(`<span className="mute">${c.name}</span> deleted`));
        handleModalVisible(false);
        handleTokenListModal(null);
        
        const response = await client.deleteTokenList(c);
        navigator.push('/lists');
    }

    async function editTokenList(data) {

        const c = { ...data };
        let ft = null;
        if(c.featuredToken !== undefined && Object.keys(data).length < 3){
            ft = c.featuredToken;
            c.featuredToken = ft ? ft._id : null;
        }
        

        const response = await client.editTokenList(c);

        let name = response.data?.name ? response.data.name : 'list';

        delete c._id;

        let tl = {
            ...tokenList,
            ...c
        }
        if(ft){
            tl.featuredToken = ft;
        }

      
        setTokenList(tl);

        let tls = [...tokenLists];
        console.log(tls)
        for (var i=0;i<tls.length;i++){
            if(tls[i]._id == tl._id){
                tls[i] = {
                    ...tls[i],
                    ...tl
                }
            }
        }
        setTokenLists(tls);

        toast(parse(`<span className="mute">${name}</span> updated`));

        // setCollections({ ...collections, [response.data._id]: response.data });
        // setThemesRevert({ ...themesRevert, [response.data._id]: response.data })
    }

    // Return the user object and auth methods
    return {
        welcomeMessage,
        loading,
        dexSearchPairs,
        handleDexSearchPairs,
        dexSearch,
        handleDexSearch,
        debouncedDexSearch,
        swapVisible,
        handleSwapVisible,
        swapExchange,
        handleSwapExchange,
        swapUrl,
        handleSwapUrl,
        dexChartVisible,
        handleDexChartVisible,
        tbx,
        tby,
        handleScreenClickDims,
        token,
        tokenPair,
        handleTokenPair,
        handleTokenPairUpdate,
        tokenHistory,
        closeTokenPair,

        handleTokenOrder,
        handleTokenFilters,
        tokenFilters,
        handleTokenSort,
        tokenOrder,

        tokenSort,
        dexView,
        trendingView,
        listView,
        handleDexView,
        handleTrendingView,
        handleListView,
        dexViewChartInit,
        dexViewSwapInit,
        handleDexViewChartInit,
        handleDexViewSwapInit,
        showTicker,
        handleShowTicker,

        editFilters,
        handleEditFilters,

        tokenPairs,
        caToken,

        ethTrendingChain,
        ethTrendingKey,
        handleEthTrendingKey,
        trendingChain,
        handleTrendingChain,
        ethTrendingTokens,
        fetchEthTrendingTokens,

        trendingTokens,
        trendingTokensChannels,

        tokenTwitterUsers,

        tokenHealth,
        handleToken,
        searchDex,
        dexResults,
        dexUpdateTime,
        fetchFromDex,
        fetchTokenPair,
        fetchPairsQuery,
        handleHealthCheck,
        tokenHealth,

        fetchPortfolioTokens,
        portfolioTokens,
        ethFactor,

        handleNewPair,
        newPairs,  // deprecated
        pairs,
        fetchNewPairs,
        trendingPairs,
        tickerPairs,
        fetchTickerPairs,
        tokenLists,
        tokenListId,
        adminTokenLists,
        fetchAdminTokenLists,
        createTokenList,
        deleteTokenList,
        editTokenList,
        tokenList,
        handleTokenList,
        handleAddToTokenList,
        removeTokenFromList,
        saveListOrder,
        listTokens,
        fetchTokenList,
        duplicateTokenList,

        exploreLists,
        fetchExploreLists,
        
       
        handleListTokens,
        homePartnerList,
        homeMeta,
        fetchHomeMeta,

        apeSettings,
        tokenToolbarVisible,
        handleTokenToolbarVisible,

        blankOwnerAddress,
        deadWalletAddress,
        tokenDataTime,
        handleTokenDataTime,
        removeSiteFeaturedToken,
        addSiteFeaturedToken
    };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideDex({ children }) {
    const dex = useProvideDex();
    return <dexContext.Provider value={dex}>{children}</dexContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useDex = () => {
    return useContext(dexContext);
};
