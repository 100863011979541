import React from "react";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale } from "../../utils/currency";

import classNames from "classnames";

import { addCommas } from "../../utils/tools";

var parse = require('html-react-parser');



export function TokenDataTabsInteractive({
    passClick,
    buttonValue,
    buttonSubValue=null,
    buttonValues,
    values,
    showValues=true,
    valuesFormat,
    labels=[],
    dataTips=[],
    images=[],
    svgs=[],
    subImages=[],
    addClass,
    addStyle=null,
    styles=[],
    subImageStyle=null,
    dataItemStyle={},
    bookend=null,
    disabled=false
}) {

    const handleClick = (v) => {
        passClick(v);
    }

    return (
        <div style={addStyle ? addStyle : {}} className={classNames("data-row interactive-items bg flex-row justify space-around", bookend && "bookend", addClass && addClass, disabled && "disabled")}>
            { buttonValues.map(( v, i) => (
                <div
                key={`ti${i}`}
                data-tip={`${dataTips[i] !== undefined && dataTips[i].length ? dataTips[i] : labels[i] || values[i]}`}
                onClick={() => handleClick(v)}
                style={ values[i].toString().indexOf('icon-') > -1 ? {display: "flex", alignItems:"center", justifyContent: "center", minWidth: `${(100/buttonValues.length)}%`} : styles[i] !== undefined ? {...dataItemStyle,...styles[i]} : {  ...dataItemStyle } }
                className={classNames("data-item", v == buttonValue && 'active', v == buttonSubValue && 'sub-active', buttonSubValue && 'sub-nav' )}>

                {images && images[i] !== undefined  ? (
                    <div className="data-tab-image-container">
                        <div className="data-tab-image"><img src={images[i]}/></div>
                    </div>
                ):null}

                {svgs && svgs[i] !== undefined  ? (
                    <div className="data-tab-image-container">
                        <div style={{transform:"scale(.88)"}} className="svg-icon">
                            {svgs[i]}
                        </div>
                    </div>
                ):null}

                {subImages && subImages[i] !== undefined && subImages[i]  ? (
                    <div style={subImageStyle ? subImageStyle : {position:"absolute",width:'17px',right:3,top:3}} className="data-tab-image-container">
                        <div className="data-tab-image">
                            { subImages[i].indexOf('icon-') > -1 || subImages[i].indexOf('icon-button') > -1 ? (
                                <div className={`${subImages[i]}`}></div>
                            ):(
                                <img src={subImages[i]}/>
                            )}
                            
                            
                        </div>
                    </div>
                ):null}
                    { labels[i] !== undefined ? (
                        <>
                            { labels[i].indexOf('icon-') > -1 || labels[i].indexOf('icon-button') > -1 ? (
                                <div className={`${labels[i]}`}></div>
                            ):(
                                <div className="data-title">{labels[i]}</div>
                            )}
                        </>
                        
                    ):null}
                    
                    { showValues ? (

                        <div style={{
                            fontSize: Math.abs(values[i])>=10000 ? '.7em' : Math.abs(values[i])>=1000 ? '.8em' : '13px',
                            marginTop: Math.abs(values[i])>=10000 ? 2 : Math.abs(values[i])>=1000 ? 1 : 0
                        }} className="data-value percent">
                            { valuesFormat[i] == "percent" ? (
                                <>
                                    { (values[i] + '').indexOf('e+') > -1 ? (
                                        <>

                                        </>

                                    ) : (
                                        <>
                                            {values[i] < 777777 ? parse(formatPercent(String(values[i]))) : "--"}
                                        </>

                                    )}

                                </>
                            ) : valuesFormat[i] == "currency" ? (
                                <>
                                    {parse(condenseZeros(formatCurrency(values[i], "USD")))}
                                </>
                            ) : valuesFormat[i] == "currencymin" ? (
                                <>
                                    {parse(formatCurrencyScale(values[i]))}
                                </>
                            ) : (
                                <>
                                    {values[i].indexOf('icon-') > -1 ? (
                                        <>
                                           <div style={{margin:0,marginRight:'0 !important'}} className={values[i]}></div>
                                        </>
                                    ):(
                                        <>
                                            {addCommas(values[i])}
                                        </>
                                    )}
                                    
                                </>
                            )}

                        </div>
                    ):null}

                    {/*{images && images[i] !== undefined  ? (
                        <div className="data-tab-image-container">
                            <div className="data-tab-image"><img src={images[i]}/></div>
                        </div>
                    ):null}*/}


                </div>
            ))}
        </div>
    );
}
