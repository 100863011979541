// src/components/RadarChart.js
import React, { useEffect, useState, useRef } from 'react';
import { Radar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS, RadialLinearScale, CategoryScale, LinearScale, BarElement, PointElement, LineElement, RadarElement, Title, Filler, Tooltip, Legend } from 'chart.js';

import { DexPairItem } from '../Dex/DexPairItem';

import { useCore } from '../../hooks/useCore';
import { useDex } from '../../hooks/useDex';
import { useNavigator } from '../../hooks/useNavigator';

import classNames from 'classnames';
import { getVibrantColorGradient } from '../../utils/data';
import { getTimeDiff } from '../../utils/tools';
import { DexPairCard } from '../Dex/DexPairCard';

export function RadarChart({ trendingChannels, trendingNames, trendingTokens=[], passClick=null}) {

    const navigator = useNavigator();

    const chartRef = useRef(null);

    const shouldDrawCircles = useRef(true);

    ChartJS.register( zoomPlugin );

    const { pageSize, ethTrending, listView, radarChartInit, handleRadarChartInit } = useCore();
    const { handleTrendingView, ethTrendingKey } = useDex();

    const [ data, setData ] = useState({
        labels: [],
        datasets: []
    });

    const [tokenRollAddress, setTokenRollAddress] = useState(null);

    const [channelColor, setChannelColor] = useState("#4dc1f0");
    const [vColors, setVColors] = useState([]);
    const [nColors, setNColors] = useState([]);
    const [rollBorderColor, setRollBorderColor] = useState("#4dc1f0");
    const [rollBackgroundColor, setRollBackgroundColor] = useState("#4dc1f0");
    const [neutralBorderColor, setNeutralBorderColor] = useState("44,44,44");
    const [neutralBackgroundColor, setNeutralBackgroundColor] = useState("133,144,155");
    const [neutralBackgroundOpacity, setNeutralBackgroundOpacity] = useState(.33);
    const [neutralBorderOpacity, setNeutralBorderOpacity] = useState(.33);
    const [activeGreenColor, setActiveGreenColor] = useState('rgba(0, 255, 222, 0.5)');

    const [trendingTokensReverse, setTrendingTokensReverse] = useState([]);

    const trendingImages = trendingChannels.map((tc, i) => (
        require(`../../icons/trending/logo_${tc}.png`)
    ));

    useEffect(() => {

        let vrid = Math.floor(Math.random()*27);
        let nrid = Math.floor(Math.random()*7);
       
        let vcolorsTemp = getVibrantColorGradient(vrid, trendingTokens.length);
        let ncolorsTemp = getVibrantColorGradient(3, trendingTokens.length, "neutral");

        setVColors(vcolorsTemp);
        setNColors(ncolorsTemp)
        setChannelColor(vcolorsTemp[0]);


         // console.log(vcolors,ncolors)
        // let fincolors = {
        //     green: getVibrantColorGradient(Math.floor(Math.random()*7),10,"green"),
        //     neutral: getVibrantColorGradient(Math.floor(Math.random()*7),10,"neutral"),
        //     red: getVibrantColorGradient(Math.floor(Math.random()*7),10,"red"),
        // }

    }, [ ethTrendingKey ]);

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current; // Access the chart instance
            const canvas = chart.canvas; // Get the canvas element

            // Dispatch a mouseover event on the canvas
            const event = new MouseEvent('mousemove', {
                bubbles: true,
                cancelable: true,
                clientX: canvas.offsetLeft + canvas.width / 2, // Adjust the coordinates as needed
                clientY: canvas.offsetTop + canvas.height / 2,
            });

            canvas.dispatchEvent(event); // Fire the event
        }
    }, [data]);

    useEffect(() => {


        let now = new Date();
        let d = {
            labels: trendingNames,
            datasets: []
        }
       

        let darkGrey = "#333333";
        let vcount = 0;
        
        for(var i=0;i<trendingTokens.length;i++){

            let dcolor = nColors[0];

            let pcolors = [];

            let t = trendingTokens[i]; 
            let tt = t.trending; 
            
            let td = [];
            let ranks = [];
            let times = [];
            let prs = [];
            let trending = [];
            // console.log(t.name,t.symbol)
            let channelTokenCount = 0;
            for(var j=0;j<trendingChannels.length;j++){

                let pcolor = {
                    bg: neutralBackgroundColor,
                    border: neutralBorderColor,
                    bghover: rollBackgroundColor,
                    borderhover: rollBorderColor
                };


                if(tt[trendingChannels[j]] !== undefined){
                    
                    
                    // in here found trending
                    trending.push(trendingChannels[j]);

                    if(tt[ethTrendingKey] !== undefined){
                        vcount+=1;
                        dcolor = vColors[0];
                        pcolor = {
                            bg: darkGrey,
                            border: vColors[channelTokenCount],
                            bghover: vColors[channelTokenCount],
                            borderhover: vColors[channelTokenCount],
                        }

                    }
                    let dt = tt[trendingChannels[j]];
                   
                    let v = 10 - ((dt.rank)/ethTrending[trendingChannels[j]]?.length)*10;
                    // console.log(dt.rank,v)
                    td.push( v );
                    // td.push(6.5)
                    ranks.push(dt.rank);
                    times.push(dt.startedAt);
                    let time = getTimeDiff(now,dt.startedAt);

                    let pr = 4 + time/3;
                    if(pr > 13){
                        pr = 11;
                    }
                    prs.push(pr);
                    
                }else{
                    pcolor={
                        bg: 'transparent',
                        border:'transparent',
                        bghover: 'transparent',
                        borderhover: 'transparent',
                    }

                    // if(tt[ethTrendingKey] !== undefined){
                    //     pcolor = {
                    //         bg: darkGrey,
                    //         border: vcolors[channelTokenCount],
                    //         bghover: vcolors[channelTokenCount],
                    //         borderhover: darkGrey
                    //     }
                    // }

                    td.push(0);
                    ranks.push(-1);
                    times.push(0);
                    prs.push(0);
                }

                pcolors.push(pcolor);
                channelTokenCount += 1;

            }

            

            let dr = {
                label: t.name,
                symbol: t.symbol,
                address: t.address,
                chain: t.chainId,
                tc: ethTrendingKey || null,
                // rank: tt[ethTrendingKey] !== undefined ? tt[ethTrendingKey].rank : -1,
                // startedAt: tt[ethTrendingKey] !== undefined ? tt[ethTrendingKey].startedAt : 0,
                ranks: ranks,
                times: times,
                prs: prs,
                trending: trending,
                // data: td,
                fill: true,
                color: dcolor,
                index: i,
                pcolors: pcolors,
                backgroundColor: `rgba(${dcolor}, ${getDataOrderOpacity(i)})`,
                // borderColor: `rgba(${dcolor},.5)`,
                // backgroundColor: (ctx) => `rgba(${pcolors[ctx.dataIndex]["bg"]}, ${getDataOrderOpacity(i)})`,
                // borderColor: (ctx) => `rgba(${pcolors[ctx.dataIndex]["border"]}, ${getDataOrderOpacity(i)})`,
                order: 80-i,
                // pointBackgroundColor: `rgb(${pcolor["bg"]})`,
                // pointBorderColor: `rgb(${pcolor["border"]})`,
                // pointHoverBackgroundColor: `rgb(${pcolor["bghover"]})`,
                // pointHoverBorderColor: `rgb(${pcolor["borderhover"]})`,

                pointBackgroundColor: `rgba(${dcolor},.4)`,
                pointBorderColor: `rgba(${dcolor},.4)`,
                // pointHoverBackgroundColor: (ctx) => `rgb(${pcolors[ctx.dataIndex]["bghover"]})`,
                // pointHoverBorderColor: (ctx) => `rgb(${pcolors[ctx.dataIndex]["borderhover"]})`,
                // pointHoverBackgroundColor: rollBackgroundColor,
                // pointHoverBorderColor: rollBorderColor,
                // pointRadius: prs[0],
                pointRadius: (ctx) => (prs[ctx.dataIndex]), // Controls the size of data points (default: 3)
                pointHoverRadius: (ctx) => (prs[ctx.dataIndex] + 2), // Size of points on hover
                icons: trendingImages,
                zIndex: (3+i)
            }


            dr.data = td;
            d.datasets.push(dr);

        }

        setData(d);
		
        handleRadarChartInit(true);
		
	}, [ trendingTokens, ethTrendingKey ]);

    const rollTokenOnChart = (a) => {
        if(tokenRollAddress !== a){
            setTokenRollAddress(a);
            setTimeout(() => {
                highlightDataset(a);
            },20)
        }
    }

    const openTokenOnChart = (a,c) => {
        if(c !== undefined){
            console.log('in oppen token',a,c)
            let v = "trending";
            v += "&tc=" + ethTrendingKey;
            navigator.push(`/${c}/${a}?view=${v}`);
        }
        
    }

    const resetZoom = () => {
        const chartInstance = chartRef.current;
        chartInstance.resetZoom();
    };
    
    var drawn = false;
    let imagePositions = []; // To store the positions of images for interactivity
    let hoveredIndex = -1;
    let trendingIndex = -1;

    const radarIcons = {
        id: 'radarIcons',
        
        beforeDatasetsDraw (chart) {
        // afterDraw (chart) {

            if (drawn) return;
             
            
            const { ctx, data, scales: { r } } = chart;
            
            
            
            ctx.restore();
        },
        resize (chart) {

            if (drawn) return;
             

            
            const { ctx, data, scales: { r } } = chart;
            const xCenter = chart.width/2;
            const yCenter = chart.height/2;
            const drawingArea = chart.width*.9;
    
            const angleLines = data.datasets[0]?.icons?.length || 0;
            const angleStep = (2 * Math.PI) / angleLines;
            imagePositions = [];
            
            function degreesToRadians(d){
                return d * (Math.PI / 180);
            }
            // ctx.translate(xCenter, yCenter);
            for (let i = 0; i < angleLines; i++) {

                ctx.save();
                // const degrees = - angle * (i) + 90;
                const angle = -((360 / angleLines) * i) + 90;
                const radians = degreesToRadians(angle);

                const xCoord = xCenter + Math.cos(radians) * (drawingArea+37);
                const yCoord = yCenter - (drawingArea+37) * Math.sin(radians);
                
                const image = new Image();
                image.src = data.datasets[0].icons[i];

                const size = 40; // Width and height of the image
                const x = xCoord - size / 2;
                const y = yCoord - size / 2;

                imagePositions.push({ x, y, size, index: i });

               
                // image.onload = () => {
                    // ctx.drawImage(image, x, y, size, size); // Draw the icon once loaded
                // };

                

                image.onload = () => {
                    // console.log('resize',hoveredIndex,i,ethTrendingKey,trendingChannels[i])
                    if (hoveredIndex === i || trendingIndex == i || data.datasets[0]?.tc == trendingChannels[i] ) {
                        // Draw a highlight for hovered image
                        ctx.beginPath();
                        ctx.arc(xCoord, yCoord, size / 2 + 5, 0, 2 * Math.PI);
                        ctx.strokeStyle = '#4dc1f0';
                        ctx.lineWidth = 3;
                        ctx.stroke();
                    }
                    ctx.drawImage(image, x, y, size, size);
                };
               
            //     // ctx.drawImage(image, x - 20, y - 20, 40, 40); // Draw icons at each point
            }
            // drawn = true;
            // ctx.save();
            ctx.restore();
        },
        beforeEvent(chart, args) {
            const { event } = args;
            const { offsetX: mouseX, offsetY: mouseY } = event;
            const { canvas } = chart;
    
            if (event.type === 'mousemove') {
                let newHoveredIndex = -1;

                imagePositions.forEach(({ x, y, size, index }) => {
                    if (
                        event.x >= x &&
                        event.x <= x + size &&
                        event.y >= y &&
                        event.y <= y + size
                    ) {
                        newHoveredIndex = index;
                    }
                    
                });

               
                if (newHoveredIndex !== hoveredIndex) {
                    hoveredIndex = newHoveredIndex;
                    let cursor = hoveredIndex !== -1 ? 'pointer' : 'default';
                    // canvas.style.cursor = cursor;
                    // console.log(event)
                    event.native.target.style.cursor = "pointer";
                    chart.canvas.style.cursor = "pointer";
                    

                    console.log('over',hoveredIndex,canvas.style.cursor)

                    hoveredIndex = newHoveredIndex;
            
        
                    chart.draw(); // Redraw for hover effect
    
                 
                }
            }
    
            if (event.type === 'click' && hoveredIndex !== -1) {
                console.log(`Image ${hoveredIndex} clicked!`);
                passClick(trendingChannels[hoveredIndex]);
                trendingIndex = hoveredIndex;
                canvas.style.cursor = "pointer";
            }
        }
        
    };

    ChartJS.register(radarIcons, CategoryScale, LinearScale, RadialLinearScale, LineElement, PointElement, Title, Filler );


    // Options for the radar chart
    const options = {
        animation: {
            duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 53,
                left: 23,
                right: 7,
                bottom: 37
            }
        },
        redraw: true,
        // elements: {
        //     line: {
        //         borderWidth: 3,
        //         borderColor: "#fff"
        //     },
        // },
      
        scales: {
            r: {
                angleLines: {
                    display: true, // Show angle lines
                    color:'rgba(99,111,123,.53)'
                },
                suggestedMin: 0, // Minimum value suggested
                suggestedMax: 10, // Maximum value suggested
                ticks: {
                    display: false,
                    stepSize: 0, // Set step size between labels
                    callback: function(value) {
                      return value; // Display labels from 1 to 10
                    },
                    font: {
                      size: 14, // Font size for the labels
                      weight: 'bold', // Font weight
                      family: "'Arial', sans-serif", // Font family
                      color: '#fff', // Label color
                    }
                },
                grid:{
                    color:'rgba(99,111,123,.53)'
                },
                pointLabels:{
                    opacity:0,
                    // padding: pageSize.width > 700 ? 34 : 0,
                    // display: pageSize.width > 700,
                    padding: 34,
                    display: true,
                    // align: 'bottom', // Position of the labels ('top', 'center', 'bottom')
                    // anchor: 'end', // Anchor point of the labels
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Background color of labels
                    borderRadius: 5, // Rounded corners
                    color: 'transparent', // Text color
                    font: {
                        size: 13, // Font size
                        weight:'600',
                        family:"Saira"
                    },
                    // padding: 6, // Padding inside the label
                    formatter: (value, context) => `\$${value}`,
                    
                }
            },
        },
        plugins: {
            // title: {
            //     display: true,
            //     text: 'Radar Chart Example', // Your title text
            //     color: '#ffffff', // Title color
            //     font: {
            //         size: 18, // Title font size
            //         weight: 'bold', // Title font weight
            //     },
            // },
            // tooltip: {
            //     enabled: true, // Enable tooltips
            //     backgroundColor: 'rgba(0, 0, 0, 0.8)', // Tooltip background color
            //     titleFont: {
            //         size: 14, // Tooltip title font size
            //         weight: 'bold',
            //         family:"Saira"
            //     },
            //     bodyFont: {
            //         size: 12, // Tooltip body font size
            //         family:"Saira"
            //     },
            //     callbacks: {
            //         label: function(context) {
            //             // Custom tooltip label format
            //             const label = context.dataset.label || '';
            //             const value = context.raw;
            //             return `${label}: ${value}`;
            //         }
            //     }
            // },
            // legend: {
            //     display: false, // Display the legend
            //     position: 'top', // Legend position
            //     labels: {
            //         color: '#ffffff', // Legend label color
            //         font: {
            //             size: 12, // Legend font size
            //             family: "Saira"
            //         },
            //     },
            // },

            
            // zoom: {
            //     zoom: {
                    
            //         enabled: true,
            //         // drag: true,
            //         mode: 'xy',
            //         overScaleMode: 'xy',
            //         speed: 5,
            //         sensitivity: 1,
            //         onZoom: (event) => {
            //             console.log('Zooming:', event); // Debugging log to track zoom
            //         },
            //         wheel: {
            //             enabled: true, // Enable zooming with mouse wheel
            //             modifierKey: 'ctrl', // Only zoom with `ctrl` key pressed
            //             mode: 'xy',
            //             onZoom: (event) => {
            //                 console.log('Zooming:', event); // Debugging log to track zoom
            //             },
            //         },
            //         pinch: {
            //             enabled: true, // Enable pinch zoom for touch devices
            //         },
            //         // drag: {
            //         //     enabled: true, // Enable drag-to-zoom
            //         // },
            //     },
            //     pan: {
            //         enabled: true, // Enable panning
            //         mode: 'xy', // Allow panning on both axes
            //         overScaleMode: 'xy',
            //         speed: 5,
            //         sensitivity: 1,
            //         onPan: (event) => {
            //             console.log('Panning:', event); // Debugging log to track pan
            //         },
            //     },
            
            // },
            radarIcons: true,
            tooltip: false,
            legend: false,
            datalabels: {
                display: false, // Disable data labels
            },
            
        },

      
        interaction: {
            mode: 'nearest', // Interaction mode
            intersect: true, 
            axis: 'xy'
        },

        
        onHover: (event, chartElement) => {
            const chartInstance = event.chart;
            const canvas = event.native?.target || event.chart.canvas;
            

            const nativeEvent = event.native;

            if (!nativeEvent) return; // Ensure the event exists

            // Get the mouse position relative to the window
            


            if (chartElement.length > 0) {

                const mouseX = nativeEvent.clientX; // Horizontal position
                const mouseY = nativeEvent.clientY; // Vertical position

                console.log(`Mouse X: ${mouseX}, Mouse Y: ${mouseY}`);


                shouldDrawCircles.current = true;

                const datasetIndex = chartElement[0].datasetIndex;
                console.log('Hover datasetIndex:', datasetIndex);
                canvas.style.cursor = 'pointer';

                let ra = chartInstance.data.datasets[datasetIndex].address;
                setTokenRollAddress(ra);
                
                
                // Loop through all datasets to update their colors and zIndex
                chartInstance.data.datasets.forEach((dataset, index) => {
                    if (dataset.address == ra) {
                        // Change color for hovered dataset
                        dataset.backgroundColor = activeGreenColor; // Green on hover
                        dataset.borderColor = rollBorderColor;
                        dataset.order = -40; // Bring the hovered dataset to the top
                        dataset.pointBackgroundColor = rollBackgroundColor;
                        dataset.pointBorderColor = rollBorderColor;

                        // dataset.pointBackgroundColor = dataset.pcolors.map(c => c.bghover);
                        // dataset.pointBorderColor = dataset.pcolors.map(c => c.borderhover);
                        dataset.pointRadius = 9;
                        dataset.pointRadius = dataset.prs.map(v => v + 2);
                    } else {

                        // let rset = dataset.address == tokenRollAddress;
                        let rset = dataset.trending.includes(ethTrendingKey);
                        dataset.backgroundColor = rset ? `rgba(${dataset.color},${getDataOrderOpacity(index)})` : `rgba(${dataset.color}, ${getDataOrderOpacity(index)})`;
                        dataset.borderColor = rset ? `rgba(${neutralBorderColor},.4)` : neutralBorderColor;
                        dataset.order = rset ? 1 : 1;
                        // dataset.pointBackgroundColor = rset ? `rgba(${dataset.color},.4)` : neutralBackgroundColor;
                        // dataset.pointBorderColor = rset ? `rgba(${dataset.color},.4)` : neutralBorderColor;
                        dataset.pointBackgroundColor = `rgba(${neutralBackgroundColor},.4)`;
                        dataset.pointBorderColor = `rgba(${neutralBorderColor},.4)`;


                        dataset.pointRadius = dataset.prs;
                    }
                });
        
                // Re-render the chart after modifying dataset properties
                
            } else {
                // Reset cursor when no point is hovered
                shouldDrawCircles.current = true;
                canvas.style.cursor = 'default';
                setTokenRollAddress(null);

                chartInstance.data.datasets.forEach((dataset, i) => {
            
                    let rset = dataset.address == tokenRollAddress;
                    let inChannel = dataset.trending.includes(ethTrendingKey);
                    dataset.backgroundColor = rset ? 'rgba(0, 255, 222, 0.5)' : inChannel ? `rgba(${dataset.color}, ${getDataOrderOpacity(i)})` : `rgba(${neutralBackgroundColor},${neutralBackgroundOpacity})`;
                    dataset.borderColor = rset ? rollBorderColor : inChannel ? `rgba(${neutralBorderColor},${neutralBorderOpacity})` : `rgba(${neutralBorderColor},${neutralBorderOpacity})`;
                    dataset.order = rset ? 40 : inChannel ? 1 : 1;
                    dataset.pointBackgroundColor = rset ? rollBackgroundColor : inChannel ? `rgba(${dataset.color},.4)` : `rgba(${neutralBackgroundColor},${neutralBackgroundOpacity})`;
                    dataset.pointBorderColor = rset ? rollBorderColor : inChannel ? `rgba(${dataset.color},.4)` : `rgba(${neutralBorderColor},${neutralBorderOpacity})`;
                    dataset.pointRadius = dataset.prs;
                    
                });
            }

            chartInstance.update();
        },
        
    
        onClick: (event, chartElement) => {
            const chartInstance = event.chart;
            if (chartElement.length > 0) {
                const datasetIndex = chartElement[0].datasetIndex;
                let t = chartInstance.data.datasets[datasetIndex];
                const label = chartInstance.data.datasets[datasetIndex].label;
              
                let v = "trending";

                if(listView=="trending"){
                    v += "&tc=" + ethTrendingKey;
                }
              
                navigator.push(`/${t.chain}/${t.address}?view=${v}`);
                    
            }
        }
    };

    const navCirclePlugin = {
        id: 'navCircles',
        afterDraw: (chart) => {
          if (!shouldDrawCircles.current) return;
    
          const { ctx, data, scales: { r } } = chart;
            const xCenter = r.xCenter;
            const yCenter = r.yCenter;
            const drawingArea = r.drawingArea;

            const labels = chart.data.labels;
            const scale = chart.scales.r;
            const borderRadius = 4; // Border radius for rounded corners

            ctx.save(); // Save the current state

            if(pageSize.width > 700){
                ctx.font = `${scale.options.pointLabels.font.weight} ${scale.options.pointLabels.font.size}px ${scale.options.pointLabels.font.family}`;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';

                let padding = 22;

                labels.forEach((label, index) => {
                    
                    if(pageSize.width > 7700){
                        

                        const pointLabelPosition = scale.getPointPosition(index, scale.height / 2 + padding); // Position of the label

                        // console.log(pointLabelPosition)

                        let plPosition = pointLabelPosition;

                        // console.log(label, Math.cos(plPosition.angle),index)
                        if(Math.abs(Math.cos(plPosition.angle)) > .82 ){
                            plPosition.y += 33;
                        }
                        // Calculate width and height of the background box based on label size
                        const labelWidth = ctx.measureText(label).width + 20; // Width of the label with padding
                        const labelHeight = scale.options.pointLabels.font.size + 10; // Height of the label with padding

                        // Draw background (square with border radius)
                        ctx.fillStyle = 'rgba(22, 22, 22, 0.6)'; // Background color (semi-transparent blue)
                        ctx.beginPath();
                        ctx.moveTo(plPosition.x - labelWidth / 2 + borderRadius, plPosition.y - labelHeight / 2);
                        ctx.lineTo(plPosition.x + labelWidth / 2 - borderRadius, plPosition.y - labelHeight / 2);
                        ctx.quadraticCurveTo(plPosition.x + labelWidth / 2, plPosition.y - labelHeight / 2, plPosition.x + labelWidth / 2, plPosition.y - labelHeight / 2 + borderRadius);
                        ctx.lineTo(plPosition.x + labelWidth / 2, plPosition.y + labelHeight / 2 - borderRadius);
                        ctx.quadraticCurveTo(plPosition.x + labelWidth / 2, plPosition.y + labelHeight / 2, plPosition.x + labelWidth / 2 - borderRadius, plPosition.y + labelHeight / 2);
                        ctx.lineTo(plPosition.x - labelWidth / 2 + borderRadius, plPosition.y + labelHeight / 2);
                        ctx.quadraticCurveTo(plPosition.x - labelWidth / 2, plPosition.y + labelHeight / 2, plPosition.x - labelWidth / 2, plPosition.y + labelHeight / 2 - borderRadius);
                        ctx.lineTo(plPosition.x - labelWidth / 2, plPosition.y - labelHeight / 2 + borderRadius);
                        ctx.closePath();
                        ctx.fill(); // Fill background

                        // Draw the label text on top of the background
                        ctx.fillStyle = '#fff'; // Text color (white)
                        ctx.fillText(label, plPosition.x, plPosition.y);

                    }else{
                    
                        // chart.update();
                    }
                    
                });

            }
            


            // start icons
    
            const angleLines = data.datasets[0]?.icons?.length || 0;
            const angleStep = (2 * Math.PI) / angleLines;
            imagePositions = [];
            
            function degreesToRadians(d){
                return d * (Math.PI / 180);
            }
            // ctx.translate(xCenter, yCenter);
            for (let i = 0; i < angleLines; i++) {

                ctx.save();
                // const degrees = - angle * (i) + 90;
                const angle = -((360 / angleLines) * i) + 90;
                const radians = degreesToRadians(angle);

                const xCoord = xCenter + Math.cos(radians) * (drawingArea+37);
                const yCoord = yCenter - (drawingArea+37) * Math.sin(radians);
                
                const image = new Image();
                image.src = data.datasets[0].icons[i];

                const size = 40; // Width and height of the image
                const x = xCoord - size / 2;
                const y = yCoord - size / 2;

                imagePositions.push({ x, y, size, index: i });

               
                // image.onload = () => {
                    // ctx.drawImage(image, x, y, size, size); // Draw the icon once loaded
                // };

                

                // image.onload = () => {
                    // console.log('afterdraw',data.datasets[i]?.tc,trendingChannels[i])
                    if ( hoveredIndex == i || data.datasets[0]?.tc == trendingChannels[i] ) {
                        // Draw a highlight for hovered image
                        ctx.beginPath();
                        ctx.arc(xCoord, yCoord, size / 2 + 5, 0, 2 * Math.PI);
                        ctx.strokeStyle = '#4dc1f0';
                        ctx.lineWidth = 3;
                        ctx.stroke();
                    }
                    ctx.drawImage(image, x, y, size, size);
                // };
               
           
            }
            
            
            ctx.restore();
        },
    };

    useEffect(() => {
        const canvas = document.querySelector('canvas');
        const chartInstance = canvas?.__chartjs__;
    
        // If you want to manually trigger pan functionality
        if (chartInstance) {

            chartInstance.zoom(1.2);
            chartInstance.update();
          // Example: if pan event is not working, you can invoke pan manually
        //   canvas.addEventListener('mousedown', (event) => {
        //     chartInstance.zoom({
        //       pan: {
        //         enabled: true,
        //         mode: 'xy',
        //         speed: 10,
        //       },
        //     });
        //     console.log('Manually triggered pan');
        //   });
        }
      }, []);



    

    const getDataOrderOpacity = (i) => {
        return .17+.007*i;
    }

    const highlightDataset = (a) => {
        const chartInstance = chartRef.current;
    
        if (!chartInstance) return;
    
        chartInstance.data.datasets.forEach((dataset, i) => {
            let rset = dataset.address == a;
            let inChannel = dataset.trending.includes(ethTrendingKey);
            dataset.backgroundColor = rset ? 'rgba(0, 255, 222, 0.5)' : inChannel ? `rgba(${dataset.color}, ${getDataOrderOpacity(i)})` : `rgba(${neutralBackgroundColor},${neutralBackgroundOpacity})`;
            dataset.borderColor = rset ? rollBorderColor : inChannel ? `rgba(${neutralBorderColor},${neutralBorderOpacity})` : `rgba(${neutralBorderColor},${neutralBorderOpacity})`;
            dataset.order = rset ? -40 : inChannel ? 1 : 1;
            dataset.pointBackgroundColor = rset ? rollBackgroundColor : inChannel ? `rgba(${dataset.color},.4)` : `rgba(${neutralBackgroundColor},.4)`;
            dataset.pointBorderColor = rset ? rollBorderColor : inChannel ? `rgba(${dataset.color},.4)` : `rgba(${neutralBorderColor},.4)`;
            // dataset.backgroundColor = rset ? 'rgba(0, 255, 222, 0.5)' : `rgba(${dataset.color}, ${getDataOrderOpacity(i)})`;
            // dataset.borderColor = rset ? rollBorderColor : neutralBorderColor;
            // dataset.order = rset ? -40 : 1;
            // dataset.pointBackgroundColor = rset ? rollBackgroundColor : neutralBackgroundColor;
            // dataset.pointBorderColor = rset ? rollBorderColor : neutralBorderColor;
            // dataset.pointRadius = rset ? 7 : 5;
         
        });
    
        chartInstance.update(); // Re-render the chart with updated styles
    };

    // useEffect(() => {
    //     const chart = chartRef.current;
    //     console.log(chart)
    //     if (chart) {
    //       console.log("Chart is ready, you can zoom.");
    //     } else {
    //       console.log("Chart not ready yet.");
    //     }
    //   }, []);
    
      // Zooming function
      const zoomChart = () => {
        // const chart = chartRef.current;
        const chart = chartRef.current;
        console.log(chart)
        if (chart) {
          console.log("Zooming in...");
          let zl = chart.getZoomLevel();
            console.log(zl)
            console.log("Canvas size before zoom:", chart.canvas.width, chart.canvas.height);
            chart.zoom(1.2); // Apply zoom
            chart.update(); // Ensure the chart updates
            chart.resize(); // Resize the chart
            console.log("Canvas size after zoom:", chart.canvas.width, chart.canvas.height);
        //   chart.zoom({ x: 2, y: 2 });  // Apply zoom to chart
        //   chart.update();  // Ensure chart is updated
        } else {
          console.log("Chart not found.");
        }
      };

    return (
        <div className="radar-container"
        style={{overflow:"hidden",margin: 0, width: pageSize.width, height: `${pageSize.height - 230}px` }}
        >
            <div className='flex-column' style={{flexDirecton:'column-reverse',position:"absolute",top:0,left:0}}>
                {/* { trendingTokens
                .filter(t => t.trending[ethTrendingKey] !== undefined)
                .sort((a,b) => a.trending[ethTrendingKey]?.rank > b.trending[ethTrendingKey]?.rank ? 1 : -1 )

                .map((p,i) => (
                    <DexPairItem
                        key={`tdpi${i}`}
                        withSpace={true}
                        i={i}
                        rankAnimation={false}
                        // passClick={onDexTokenClick}
                        passMouseOver={openTokenOnChart}
                        pairData={p.data}
                        pair={p}
                        activeColor={p.ta == tokenRollAddress ? `rgba(${channelColor},.77)` : null}
                        // coreToken={token}
                    />
                ))} */}


                {/* .map((t,i) => (
                    <button
                    style={{margin:3}}
                    className={classNames("outline", tokenRollAddress == t.address ? "active" : "")}
                    active={true}
                    onClick={() => openTokenOnChart(t.address,t.chainId)}
                    onMouseEnter={() => rollTokenOnChart(t.address)}
                    onMouseLeave={() => openTokenOnChart(-1)}
                    >{t.name}</button>
                ))} */}
               
               {/* <button onClick={resetZoom}>Reset Zoom</button>  */}
               {/* <button onClick={zoomChart}>zoom chart</button>  */}
            </div>
            <Radar ref={chartRef} data={data} options={options} plugins={[navCirclePlugin]} />
        </div>
    );
}


export const RadarChartMemo = React.memo(RadarChart);

