import React from "react";

import { useDex } from "../../hooks/useDex";

import classNames from "classnames";

import {ReactComponent as ChartLineIcon} from '../../icons/svgs/chartlineicon.svg';
import { useCore } from "../../hooks/useCore";
import { TokenListItemSymbol } from "../Dex/TokenListItemSymbol";

export function Footer() {

    let {
        mobileView,
        handleMobileView,
        sidebarLeft,
        handleSidebarLeft,
        sidebarRight,
        handleSidebarRight
    } = useCore();

    let { 
        dexView,
        token
    } = useDex();

    const onMobileView = (v) => {
        if(v=="nav"){
            handleSidebarLeft(!sidebarLeft);
        }else{
            if(v=="sidebar" && !sidebarRight){
                handleSidebarRight(true);
            }
            if(v=="page" && sidebarRight){
                handleSidebarRight(false);
            }
            handleMobileView(v);
            handleSidebarLeft(false);
        }
        
    }

    return (
        <div id="mainfooter" className="main-footer transition-long">

            <div style={{height:'100%',paddingLeft:7}} className="flex-row align center">

                <div className={classNames("coin-links")}>

                    <div style={{paddingLeft:13,paddingRight:13}} 
                    onClick={() => onMobileView("nav") }
                    data-tip={mobileView == "sidebar" ? "Hide Sidebar" : "View Sidebar"}
                    className={classNames("coin-link active-border",
                    sidebarLeft && 'active')}>

                        <div className="icon-button-menu"/>
                        
                    </div>


                    <div style={{paddingLeft:13,paddingRight:13}} 
                    onClick={() => onMobileView("sidebar") }
                    data-tip={mobileView == "sidebar" ? "Hide Sidebar" : "View Sidebar"}
                    className={classNames("coin-link active-border",
                    token && !sidebarLeft && mobileView == "sidebar" && 'active',
                    !token && "disabled")}>

                        { !token ? (
                            <div className="icon-button-sidebar"/>
                        ):(
                            <TokenListItemSymbol addStyle={{margin:0}} addClass="overlap" i={1} token={token}/>
                        )}
                        
                        
                    </div>

               

                    <div style={{paddingLeft:13,paddingRight:13}} 
                    onClick={() => onMobileView("page") }
                    data-tip={mobileView == "page" ? "Hide Content" : "View Content"}
                    className={classNames("coin-link active-border",
                    !sidebarLeft && mobileView == "page" && 'active')}>

                        <div className="icon-button-square"/>
                        
                    </div>

                </div>


            </div>



			{/* <p>&copy; Copyright 2023 0xDex</p> */}


        </div>
    );
}
