import React, { useState, useRef, useEffect, useMemo } from "react";

import { Modal } from "./";
import { FormTokenList } from "../Form/FormTokenList";
import { InputTextField } from "../Input/InputTextField";
import { InputTextArea } from "../Input/InputTextArea";
import { InputTabs } from "../Input/InputTabs";
import { InputToggle } from "../Input/InputToggle";
import { InputColorPick } from "../Input/InputColorPick";
import { CurrencyItem } from "../SwapCurrency/CurrencyItem";
import { NFTItem } from "../Wallet/NFTItem";
import { DexPairItem } from "../Dex/DexPairItem";
import { TokenSymbolIcon } from "../Dex/TokenSymbolIcon";
import { LoaderSpinner } from "../Layout/LoaderSpinner";

// import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useForm } from "react-hook-form";
// import { useExchange } from "../../hooks/useExchange";
import { useCollection } from "../../hooks/useCollection";
import { useDex } from "../../hooks/useDex";
import { useSocial } from "../../hooks/useSocial";

import logo0x from "../../icons/logo_0xdex.png";

import { QRCodeSVG } from 'qrcode.react';
import classNames from "classnames";
import { debounce } from "lodash";
import { DebounceInput } from 'react-debounce-input';
import { addCommas } from "../../utils/tools";
// import { client } from "../../services/api";

export function PhotoModal({ navigator }) {

    const {
        photoModalData,
        handlePhotoModalData

    } = useCore();


    

    useEffect(() => {
        const handleKeyPress = (event) => {
          if (event.keyCode === 27) {
            closeModal();
          }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
    }, [  ]);


   

    const closeModal = () => {
        handlePhotoModalData(null);
      
    }

   
    return (
        <>
            <Modal
            classes="photo"
            init={photoModalData}
            close={() => closeModal()}>
                
                {photoModalData && photoModalData.channelTitle !== undefined ? (
                    <div className="flex-column" style={{}}>

                        <div className="flex-row align center" style={{padding:11}}>
                            <div className="svg-icon">
                                <div className="icon-button-image"/>
                            </div>
                            <div className="data-panel transparent flex-row align center">
                                {photoModalData.username.length ? (
                                    <img className="profile-image main" src={require(`../../icons/${photoModalData.channelType !== undefined && photoModalData.channelType ? photoModalData.channelType : ""}/logo_${photoModalData.username.toLowerCase()}.${photoModalData.channelType == "news" ? "png" : "jpg"}`)}/>
                                ):null}
                                <p style={{margin:0,marginLeft:11,fontSize: 12, fontWeight: "bold"}}>{photoModalData.channelTitle}</p>
                            </div> 
                        </div>

                        <div className="modal-photo">

                            <img src={photoModalData.photo}/>
                        </div>
                        
                       
                    </div>
                ):null}
                
                


            </Modal>

           
        </>
    );
}
