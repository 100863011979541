import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import { useNavigator } from "../../hooks/useNavigator";

import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useMarket } from "../../hooks/useMarket";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";

import { IconActionButton } from "../IconActionButton";
import { CoinList } from "../CoinList";
import { NFTTraits } from "../Wallet/NFTTraits";

import { TokenData } from "../Dex/TokenData";
import { LoaderSpinner } from "../Layout/LoaderSpinner";

import { CoinData } from "../CoinData";
import { MarketChartLineArea } from "../MarketChart/MarketChartLineArea";

import classNames from "classnames";

import { formatCurrency, condenseZeros, formatPercent } from "../../utils/currency";
import { coinColors } from "../../utils/data";

var parse = require('html-react-parser');

export function SideBarRight() {

    const navigator = useNavigator();

	let { contractAddress, handleContractAddress, screens } = useNavigator();

    let { sidebarRight, handleSidebarRight, handleMobileView } = useCore();
    let { coins, coinCurrent, handleCoinCurrent, coinsTrending } = useMarket();
    let { token, loading } = useDex();

    const coinspanel = useRef(null);

    const toggleSidebar = () => {
        if(sidebarRight){
            handleMobileView("page");
        }
		handleSidebarRight(!sidebarRight);

	}

    const switchCoin = async (c) => {
        handleCoinCurrent(c);
        console.log(c);
    }

    return (
        <div className={classNames('sidebar right', (!sidebarRight) && 'hide')}>

            <IconActionButton
                passStyle={ sidebarRight ? { top: '5px',right: '5px', transform: 'scale(.9)'} : { display: "none",top: '3px',left: '3px', transform: 'scale(.9)'} }
                type={ sidebarRight ? "arrow-left" : "arrow-right" }
                passClick={toggleSidebar}
            />

            {/*<IconActionButton type={ !sidebarRight ? "arrow-left" : "arrow-right" } addClass="sidebar-left" position="center-left" passClick={toggleSidebar}/>*/}

            { loading.dex ? (
                <div className="load-panel">
                    <LoaderSpinner/>
                </div>
            ):null}

            {/*<div className="main-menu">
*/}

                {/*<CoinData/>*/}

                {/*{coinCurrent.lineData !== undefined && (
                    <div style={{marginTop:'-76px'}}>
                        <MarketChartLineArea
                        title="sidebar right"
                        // priceScale={true} timeScale={true} crossHairVisible={true} priceLineVisible={true}
                        type="area" data={[{lineData: coinCurrent.lineData, lineColor:coinColors[coinCurrent.symbol]}]}/>
                    </div>
                )}
*/}
            {token && token.pair?.data !== undefined && token.pair.data && !loading.dex && screens[1] !== undefined && (
                <TokenData token={token}/>
            ) }

            {/*{ loading.dex && (
                <LoaderSpinner/>
            )}*/}


            {screens[1] == 'collections' && (
                <NFTTraits/>

            ) }

            {/*<CoinList />*/}


            {/*</div>*/}
        </div>
    );
}
